<template>
<div>
  <v-col cols="12" sm="8" md="6" class="mx-auto mt-5">
    <v-card>
        
      <v-col cols="12">
        <v-card-title>
          <v-icon class="mr-2">mdi-account-plus</v-icon> 
          Créer un compte Utilisateur
        </v-card-title> 
        <v-card-subtitle></v-card-subtitle>

        <v-form @submit.prevent="register">

          <v-text-field filled v-model="name" id="name" label="Nom d'utilisateur" 
                        :rules="[rules.required, rules.minName]" 
                        :error="errorRegister == 'USERNAME_EXISTS'"></v-text-field>

          <v-text-field filled v-model="email" id="email" label="E-mail" 
                        :rules="[rules.required]"
                        :error="errorRegister == 'EMAIL_EXISTS'"></v-text-field>

          <v-text-field filled v-model="password" id="password" label="Mot de passe" 
                        :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="showpwd ? 'text' : 'password'"
                        hint="Au moins 8 caractères"
                        counter
                        @click:append="showpwd = !showpwd"
          ></v-text-field>
          <v-alert v-if="errorRegister != false" 
                   dense dark color="red" class="mt-3 text-center">
                   <v-icon small>mdi-alert</v-icon>
                   <template v-if="errorRegister == 'EMAIL_EXISTS'">Cette adresse e-mail est déjà utilisée</template>
                   <template v-if="errorRegister == 'USERNAME_EXISTS'">Ce nom d'utilisateur est déjà utilisé</template>
          </v-alert>

        </v-form>
      </v-col>
          
      <v-card-actions>
        <v-spacer/>
          <v-btn class="white--text" :loading="$store.state.auth.register.loading" color="secondary" @click="register()" block>
            <v-icon>mdi-login</v-icon> Créer mon compte
          </v-btn>
      </v-card-actions>
    </v-card>

  </v-col>

  <v-col cols="12" sm="8" md="6" class="mx-auto text-center">
    Vous avez déjà un compte ?<br>
    <v-btn class="mt-3" outlined color="secondary" to="/login">
      <v-icon>mdi-login</v-icon> Connectez-vous
    </v-btn>
  </v-col>
</div>
</template>

<script>

import router from '../router/router'
import axios from 'axios'

const config = require('../config/' + process.env.NODE_ENV)

export default {
  name: 'register',
  data: () => ({
    name: '',
    email: '',
    password: '',
    showpwd: false,
    errorRegister: false,
    rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 caractères',
          minName: v => v.length >= 3 || 'Min 3 caractères',
        },
  }),
  mounted: function(){
    this.$store.dispatch('app/incStat', '/register')
    if(config.enableRegisterUser == false) 
      router.push('/login')
  },
  methods: {
    async register(){
      console.log("try to register", this.name, this.email, this.password)
      const res = await axios.post('/auth/register', 
                                    { name: this.name,
                                      email: this.email,
                                      password: this.password
                                    })
      console.log("res /auth/register", res)
      if(res.data.error == false){
        console.log("USER SAVED WITH SUCCES", res.data)
        this.errorRegister = false
        router.push('/login');
      }else{
        this.errorRegister = res.data.msg
      }
    }
  }
}
</script>
